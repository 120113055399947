import React from 'react';
import styled from 'styled-components';
import { BREAKPOINT, PADDING, COLOR } from '@latitude/core/utils/constants';
import SvgInline from '@latitude/svg-inline';
import { Heading3, Heading6 } from '@latitude/heading';
import { Box } from '@latitude/box';
import { Link } from '@latitude/link';
import { CardGroup, CardGroupItem } from '../CardGroup/CardGroup';
import Chevron from '../Chevron/Chevron';

export default () => {
const href = '/loans/loan-repayment-calculator/';
const hrefMotor = '/loans/car-loan-repayment-calculator/';

// const CalculatorLinks = () => (
return (
  <>
    <OuterBox>
      <InnerBox isResponsive>
        <Title>Calculators</Title>
        <CardGroup>
          <CardGroupItem key={1} col={3}>
            <ItemLink
              noStyle
              href={href}
              trackId="calculators-personal-loan-repayment-calculator"
              trackEventData={{
                label: 'Personal loan repayment calculator',
                location: 'Calculators',
                category: 'button-link'
              }}
              trackProductId={['PLNZLF-WEB']}
            >
              <ItemIcon name="icon-calc-pl" />
              <ItemTitle>Personal loan repayment calculator</ItemTitle>
              <Chevron direction="right" strokeBlueBright />
            </ItemLink>
          </CardGroupItem>
          <CardGroupItem key={2} col={3}>
            <ItemLink
              noStyle
              href={hrefMotor}
              trackId="calculators-car-loan-repayment-calculator"
              trackEventData={{
                label: 'Car loan repayment calculator',
                location: 'Calculators',
                category: 'button-link'
              }}
              trackProductId={['MLNZLF-WEB']}
            >
              <ItemIcon name="icon-calc-car-loan" />
              <ItemTitle>Car loan repayment calculator</ItemTitle>
              <Chevron direction="right" strokeBlueBright />
            </ItemLink>
          </CardGroupItem>
          <CardGroupItem key={3} col={3}>
            <ItemLink
              noStyle
              href="/loans/budget-planner/"
              trackId="calculators-budget-planner"
              trackEventData={{
                label: 'Budget planner',
                location: 'Calculators',
                category: 'button-link'
              }}
              trackProductId={['PLNZLF-WEB', 'MLNZLF-WEB']}
            >
              <ItemIcon name="icon-calc-budget" />
              <ItemTitle>Budget planner</ItemTitle>
              <Chevron direction="right" strokeBlueBright />
            </ItemLink>
          </CardGroupItem>
        </CardGroup>
      </InnerBox>
    </OuterBox>
  </>
);
};

const OuterBox = styled(Box)`
  background-color: #f8f8f8;
  padding-top: 24px;
  padding-bottom: 24px;
  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: 72px;
    padding-bottom: 72px;
    background-image: url(${require('./images/bg-calculators.svg')});
    background-position: center;
    background-repeat: repeat-x;
  }
`;

const InnerBox = styled(Box)`
  margin: 0 auto;
`;

const Title = styled(Heading3)`
  text-align: center;
  color: #000;
  font-size: 24px;

  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 32px;
  }
`;

const ItemLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96px;
  padding: ${PADDING.P24};
  background-color: ${COLOR.WHITE};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.49);
    transition: all 150ms ease-in-out;
  }
`;

const ItemIcon = styled(SvgInline)`
  width: 40px;
  min-width: 40px;
  margin-right: 13px;
`;

const ItemTitle = styled(Heading6)`
  margin-bottom: 0;
  flex-grow: 1;
  color: ${COLOR.BLACK};
  @media (min-width: ${BREAKPOINT.XL}) {
    font-size: 20px;
  }
`;
