import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SvgInline from '@latitude/svg-inline';

import './_chevron.scss';

const Chevron = props => {
  const { direction, className, ...otherProps } = props;
  return (
    <div
      aria-hidden="true"
      className={classnames('chevron', `chevron--${direction}`, className)}
    >
      <SvgInline {...otherProps} name="chevron" />
    </div>
  );
};

Chevron.propTyes = {
  direction: PropTypes.oneOf[('up', 'right', 'down', 'left')]
};

Chevron.defaultProps = {
  direction: 'up'
};

export default Chevron;
